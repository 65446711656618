import * as React from "react"
import Layout from "src/components/layout"
import Seo from "src/components/seo"
import ServiceHeader from 'src/components/service-header'
import footerData from "src/data/global/footer"
import Footer from "src/components/footer"
import Accordeons from "src/components/accordeons"

const atf = {
  heading: "<strong>404: Leider nicht gefunden :(</strong>",
  hasBackground: true,
  image: "services-default",
  alt: "icon of html tag",
}

const links = {
  hasBackground: false,
  text: "<strong>Vielleicht suchst du ja eine dieser Seiten: </strong>",
  accordeons: [
    {
      title: "Home",
      link: "/",
    },
    {
      title: "Leistungen",
      link: "/services",
    },
    {
      title: "Über mich",
      link: "/about",
    },
    {
      title: "Blogfolio",
      link: "/blog",
    },
  ],
}

const NotFoundPage = () => (
  <Layout>
    <Seo title="Leider nicht gefunden" />
    <ServiceHeader data={atf} />
    <Accordeons data={links} />
    <Footer data={footerData} hasBackground />
  </Layout>
)

export default NotFoundPage
